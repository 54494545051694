import { cx } from 'class-variance-authority';

import { OrderInfo, type OrderInfoProps } from '@/app/components/ui';
import { box } from '@/app/styles/ui';

interface EssenceOrderInfoProps {
  readonly address: OrderInfoProps['address'];
  readonly email: OrderInfoProps['email'];
  readonly className?: OrderInfoProps['className'];
}

const EssenceOrderInfo = ({
  address,
  email,
  className,
}: EssenceOrderInfoProps): JSX.Element => {
  return (
    <OrderInfo
      className={cx(box(), className)}
      address={address}
      email={email}
    />
  );
};

export default EssenceOrderInfo;
