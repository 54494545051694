import { PropsWithChildren } from 'react';

import { PageLayout, type PageLayoutProps } from '@/app/components/layout';
import type { ThemeConfig } from '@/types';

import EssenceHeader from './EssenceHeader';

interface Props {
  readonly pageName: PageLayoutProps['pageName'];
}

const THEME: ThemeConfig = {
  logo: 'essence',
  bgColorClass: 'bg-gray-100',
};

const EssencePageLayout = ({
  children,
  pageName,
}: PropsWithChildren<Props>) => {
  return (
    <PageLayout
      hideSocialLinks
      name="Essence Enrollment Order Portal"
      theme={THEME}
      pageName={pageName}
    >
      <EssenceHeader />
      {children}
    </PageLayout>
  );
};

export default EssencePageLayout;
